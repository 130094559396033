'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import classNames from 'classnames'
import ThemeToggler from './theme-toggle'
import styles from '../../styles/header.module.css'

export default function Header() {
  const pathname = usePathname()

  const currentRoute = `/${pathname.split('/')[1]}`

  const links = [
    { name: 'Blog', link: '/blog' },
    { name: 'Projects', link: '/projects' },
    { name: 'CV', link: '/cv' },
  ]

  return (
    <header className={styles.header}>
      <div className={styles.innerHeader}>
        <nav className={styles.nav}>
          <Link className={styles.logoLink} href="/" passHref>
            <div className={styles.headerLogo}>
              jmugliston.dev &gt;
              <span className={styles.headerLogoCursor} />
            </div>
          </Link>
          <div className={styles.headerLinks}>
            {links.map((item, index) => (
              <Link
                key={index}
                className={classNames([
                  styles.navItem,
                  currentRoute === item.link && styles.navItemActive,
                ])}
                href={item.link}
                aria-label={item.name}
                passHref
              >
                {item.name}
              </Link>
            ))}
          </div>
        </nav>
        <div>
          <ThemeToggler />
        </div>
      </div>
    </header>
  )
}
